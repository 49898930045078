import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
  <li className="blog-post-item">
    <Link to={post.frontmatter.path}>
      <figure className="blog-banner-box">
        {!post.frontmatter.thumbnail && (
          <img src="/assets/images/world-map.svg" alt="Default image" width="1280" height="800" />
        )}
        {!!post.frontmatter.thumbnail && (
          <img src={post.frontmatter.thumbnail} alt={post.frontmatter.title + " - Featured image"} width="1280" height="800" />
        )}
      </figure>
      <div className="blog-content">
        <div className="blog-meta">
          <p className="blog-category">{post.frontmatter.category}</p>
          <span className="dot"></span>
          <time datetime={post.frontmatter.date}>{post.frontmatter.date}</time>
        </div>
        <h3 className="h3 blog-item-title">{post.frontmatter.title}</h3>
        <p className="blog-text">
          {post.excerpt}
        </p>
      </div>
    </Link>
  </li>
)
export default PostLink
