import React, { useState } from "react"
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import HeroHeader from "../components/heroHeader"

const BlogPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {
  const [postCount, setPostCount] = useState(6)
  const [isLoading, setIsLoading] = useState(false) // State to track loading state

  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .slice(0, postCount)
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  const loadMorePosts = () => {
    setIsLoading(true) // Show loading animation
    setTimeout(() => {
      setPostCount(prevCount => prevCount + 6)
      setIsLoading(false) // Hide loading animation after delay
    }, 100) // Adjust the delay time as needed
  }

  return (
    <Layout>
      <Helmet>
        <title>Blog — {site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <header>
        <h2 className="h2 article-title">Blog</h2>
      </header>
      <section className="blog-posts">
        <ul className="blog-posts-list">
          {Posts}
        </ul>
        {postCount < edges.length && (
          <div className="load-more"><button
            className={`btn ${isLoading ? "hide" : ""}`}
            onClick={loadMorePosts}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="fa-primary" d="M239 345c9.4 9.4 24.6 9.4 33.9 0L377 241c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-87 87-87-87c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 345z"/><path class="fa-secondary" d="M256 512A256 256 0 1 1 256 0a256 256 0 1 1 0 512zM377 241c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-87 87-87-87c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 345c9.4 9.4 24.6 9.4 33.9 0L377 241z"/></svg>
            {isLoading ? "Loading..." : "Load More"}
          </button></div>
        )}
      </section>
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { template: { ne: "PortfolioItem" } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 90)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
            category
          }
        }
      }
    }
  }
`;

